/* You can add global styles to this file, and also import other style files */
@import "variables";

.container-fluid {
  padding: 0;
}

.row {
  margin: 0;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 20px;
}


p {
  font-size: 20px;
}

@media only screen and (max-width: $breakpoint-large) {

}





