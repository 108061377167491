@font-face {
  font-family: cagile-regular;
  src: url(assets/fonts/Cagile-Regular/Cagile-Regular.otf) format("opentype"),
  url(assets/fonts/Cagile-Regular/Cagile-Regular.otf) format('truetype'),
  url(assets/fonts/Cagile-Regular/Cagile-Regular.woff) format('woff'),
  url(assets/fonts/Cagile-Regular/Cagile-Regular.woff2) format('woff2');
}

@font-face {
  font-family: paralucent-regular;
  src: url(assets/fonts/Paralucent-Text-W00-Book/Paralucent-Text-W00-Book.otf) format("opentype"),
  url(assets/fonts/Paralucent-Text-W00-Book/Paralucent-Text-W00-Book.ttf) format('truetype'),
  url(assets/fonts/Paralucent-Text-W00-Book/Paralucent-Text-W00-Book.woff) format('woff'),
  url(assets/fonts/Paralucent-Text-W00-Book/Paralucent-Text-W00-Book.woff2) format('woff2');
}
